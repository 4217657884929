import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ImageZoom from 'react-medium-image-zoom'
import YouTube from 'react-youtube'
import getYouTubeID from 'get-youtube-id'
import LazyLoad from 'react-lazy-load';
import ArticleGrid from './../components/HomeComponents/ArticleGrid'
import Flickity from 'react-flickity-component'
const ajaxUrl = 'https://hooks.slack.com/services/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'


class ArticleTemplate extends Component {

  constructor(props) {
    super(props);
    this.getImgHeight = this.getImgHeight.bind(this);
  }

  sendMail() {
    var mail = document.getElementsByClassName('mail-adress')[0].value;
    var trainingId = document.getElementsByClassName('mail-id')[0].value;
    var text = mail+" a demandé à avoir plus d'informations concernant le training "+trainingId
    var request = new XMLHttpRequest();
    var data = 'payload=' + JSON.stringify({
       "text": text
    });
    request.open('POST', ajaxUrl, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.send(data);
    document.getElementsByClassName('mail-button')[0].classList.add('email-sent');
  }

  getImgHeight(size, imgHeight, imgWidth) {
    var windowWidth = 0;
    if (!process.env.BROWSER) {
      // global.window = {};
      // global.document = {};
      windowWidth = 1440;
      //windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    } else {
      windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    if (windowWidth > 768) {
      switch(size) {
        case "image":
          return (700*imgHeight)/imgWidth
        case "medium_image":
          return (940*imgHeight)/imgWidth
        case "big_image":
          return (windowWidth*imgHeight)/imgWidth
        default:
          return 0
      }
    } else {
      switch(size) {
        case "image":
          return ((windowWidth-40)*imgHeight)/imgWidth
        case "medium_image":
          return ((windowWidth-40)*imgHeight)/imgWidth
        case "big_image":
          return (windowWidth*imgHeight)/imgWidth
        default:
          return 0
      }
    }
  }

  render () {
    const data = this.props.data

    const flickityOptions = {
      initialIndex: 1,
      cellAlign: 'center',
      pageDots: false
    }


    const location = this.props.location
    const fburl = 'https://www.facebook.com/sharer/sharer.php?u='+location.href
    const twitterurl = 'https://twitter.com/share?url='+location.href
    var noIndex = false
    var selection = false
    if (!data.theArticle.data.category) {
      noIndex = true
    }

    if (!noIndex) {
      if (data.theArticle.data.category.url === "/category/selection") {
        selection = true
      }
    }
    return (
      <div className="article-wrapper">
        <Helmet>
          { noIndex && <meta name="robots" content="noindex"/> }
          <meta name="title" content={ data.theArticle.data.meta_title.text } />
          <title itemProp="name" lang="fr">{ data.theArticle.data.meta_title.text }</title>
          <meta name="description" content={ data.theArticle.data.meta_description.text } />
        </Helmet>
        { !selection &&
          <section>
            <div className="display-block position-relative width-100">
              <div className="wrapper--xs">
                <Link to="/">
                  <span className="link link-dark link--underline">Retour à la liste</span>
                </Link>
              </div>
            </div>
          </section>
        }
        { selection && <section></section>}
        <section>
          <div className="display-block position-relative width-100">
            <div className="wrapper--xs">
              <h1 className="display-block position-relative font--gentium c-black fontsize-xxxl breakpoint-l--fontsize-xl lineheight-1 width-100 marginbottom-l breakpoint-m--marginbottom-m">{ data.theArticle.data.title.text }</h1>
              <span className="display-block position-relative font--r fontsize-m c-black breakpoint-l--fontsize--article lineheight-1_22 width-100">{ data.theArticle.data.preview.text }</span>
            </div>
          </div>
        </section>
        <section>
          <div className="display-block position-relative width-100">
            <div className="wrapper breakpoint-l--wrapper--xl">
              <div className="marginbottom-xl breakpoint-m--marginbottom-m">
                <img className="width-100" src={ data.theArticle.data.cover.url } alt=""/>
              </div>
              <div className="display-block position-relative width-100 margintop-m textalign-center">
                <div className="display-inlineblock marginright-xxs marginleft-xxs">
                  <a href={ fburl } target="_blank" rel="noopener noreferrer">
                    <span className="inv-icon icon-facebook fontsize-l c-black"></span>
                  </a>
                </div>
                <div className="display-inlineblock marginright-xxs marginleft-xxs">
                  <a href={ twitterurl } target="_blank" rel="noopener noreferrer">
                    <span className="inv-icon icon-twitter fontsize-l c-black"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        { data.theArticle.data.body != null &&
          <div className="article-content">
            { data.theArticle.data.body.map((slice, index) => (
                <div key={ index } className={`slice-block slice-${ slice.slice_type }`}>
                  {(() => {
                      switch(slice.slice_type) {
                          case 'text':
                            return <div dangerouslySetInnerHTML={{ __html: slice.primary.slice_text.html }} />
                          case 'quote':
                            var quote = slice.primary.quote.replace(/^"(.+(?="$))"$/, '$1');
                            return <blockquote>"{ quote }"</blockquote>
                          case "image":
                            return <figure className="article-img article-img--s">
                                      <LazyLoad height={ this.getImgHeight(slice.slice_type, slice.primary.small_with_image.dimensions.height, slice.primary.small_with_image.dimensions.width) } offsetVertical={300}>
                                        <ImageZoom
                                          image={{
                                            src: `${ slice.primary.small_with_image.url }`,
                                            alt: `${ slice.primary.alt != null ? `${ slice.primary.alt }` : ""}`
                                          }}
                                        />
                                      </LazyLoad>
                                      <div className="filler" style={ {height: `${ this.getImgHeight(slice.slice_type, slice.primary.small_with_image.dimensions.height, slice.primary.small_with_image.dimensions.width) }px`} }></div>
                                      { slice.primary.caption != null && <figcaption>{ slice.primary.caption }</figcaption> }
                                    </figure>
                          case "medium_image":
                            return <figure className="article-img article-img--m">
                                      <LazyLoad height={ this.getImgHeight(slice.slice_type, slice.primary.medium_width_image.dimensions.height, slice.primary.medium_width_image.dimensions.width) } offsetVertical={300}>
                                        <ImageZoom
                                          image={{
                                            src: `${ slice.primary.medium_width_image.url }`,
                                            alt: `${ slice.primary.alt != null ? `${ slice.primary.alt }` : ""}`
                                          }}
                                        />
                                      </LazyLoad>
                                      <div className="filler" style={ {height: `${ this.getImgHeight(slice.slice_type, slice.primary.medium_width_image.dimensions.height, slice.primary.medium_width_image.dimensions.width) }px`} }></div>
                                      { slice.primary.caption != null && <figcaption>{ slice.primary.caption }</figcaption> }
                                    </figure>
                          case "big_image":
                            var imgHeight = this.getImgHeight(slice.slice_type, slice.primary.full_width_image.dimensions.height, slice.primary.full_width_image.dimensions.width)
                            return <figure className="article-img article-img--xl">
                                      <LazyLoad height={ imgHeight } offsetVertical={300}>
                                        <img src={ slice.primary.full_width_image.url } alt={ slice.primary.alt != null ? `${ slice.primary.alt }` : ""} />
                                      </LazyLoad>
                                      <div className="filler" style={ {height: `${ imgHeight }px`} }></div>
                                      { slice.primary.caption != null && <figcaption>{ slice.primary.caption }</figcaption> }
                                    </figure>
                          case "video":
                            return <div className="embed-wrapper">
                                      <div className="yt-video">
                                        <YouTube videoId={getYouTubeID(`${ slice.primary.embed.embed_url }`)}/>
                                      </div>
                                      { slice.primary.caption != null && <span className="video-caption">{ slice.primary.caption }</span> }
                                    </div>
                          case "gallery":
                            return <div className="gallery-wrapper">
                                      <div className="grid grid--3 grid--m">
                                        { slice.items.map((item, index) => (
                                          <div key={ index }>
                                            <ImageZoom
                                              image={{
                                                src: `${ item.image.url }`,
                                                alt:  "",
                                                className: "width-100"
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                          case "button":
                            return <p className="textalign-center">
                                    <a href={ slice.primary.button_link.url } target="_blank" rel="noopener noreferrer">
                                        <button className="button button--m bg-purple">
                                          <span className="font--sb fontsize-sm c-white">{ slice.primary.button_label.text }</span>
                                        </button>
                                    </a>
                                  </p>
                          case "mail_form":
                            return <div className="wrapper--s breakpoint-l--wrapper--xl">
                                      <div className="width-100 padding-xxl breakpoint-m--padding-s bg-grey--ul textalign-center">
                                        <div className="width-100 marginbottom-m">
                                          <span className="display-block font--sb fontsize-m c-black  maxwidth-520 margin0auto">{ slice.primary.custom_text_message.text }</span>
                                        </div>
                                        <div className="input-custom">
                                            <span className="input-custom--label">{ slice.primary.input_label }</span>
                                            <input type="hidden" className="mail-id" value={ slice.primary.id_training } />
                                            <input className="input-custom--input mail-adress" type="text" placeholder="email@adress.com" />
                                            <button className="button input-custom--button mail-button" onClick={this.sendMail}>
                                              <span className="font--r fontsize-m c-white font--sb mail-button-label">{ slice.primary.cta_label }</span>
                                              <span className="inv-icon icon-check c-white fontsize-l validate-icon"></span>
                                            </button>
                                        </div>
                                      </div>
                                    </div>
                          case "ad":
                            return <div className="wrapper--s breakpoint-l--wrapper--xl">
                                      <div className="width-100 padding-xxl breakpoint-m--padding-s bg-grey--ul">
                                        <div className="wrapper--xs section-article-ad">
                                          <div className="section-article-ad--img">
                                            <img src={ slice.primary.ad_image.url } alt={ slice.primary.institution } />
                                          </div>
                                          <div className="section-article-ad--course">
                                            <span className="font--sb fontsize-sm c-purple marginbottom-s">{ slice.primary.institution }</span>
                                            <span className="font--sb fontsize-m c-black marginbottom-m">{ slice.primary.course_name }</span>
                                            <span className="font--r fontsize-s c-black">{ slice.primary.course_overview }</span>
                                          </div>
                                        </div>
                                        <div className="textalign-center">
                                          <a href={ slice.primary.course_link.url } target="_blank" rel="noopener noreferrer">
                                              <button className="button button--m bg-purple">
                                                <span className="font--sb fontsize-sm c-white">Check this course on Invivox</span>
                                              </button>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                          case "course_slide":
                            return <div className="wrapper--xl">
                                      <div className="width-100 padding-xxl breakpoint-m--padding-s bg-grey--l">
                                        <Flickity
                                            className={'carousel'} // default ''
                                            elementType={'div'} // default 'div'
                                            options={flickityOptions} // takes flickity options {}
                                            disableImagesLoaded={false} // default false
                                            reloadOnUpdate // default false
                                            static // default false
                                          >
                                          { slice.items.map((item, index) => (

                                            <div key={ index } className="course-item">
                                              <div className="course--mini ">
                                                <div className="course--mini--picture">
                                                  <img src={ item.course_department_logo.url }/>
                                                </div>
                                                <div className="course--mini--faculty">
                                                  <span className="display-block truncatetext font--sb fontsize-sm link link-purple">{ item.course_department_name.text }</span>
                                                </div>
                                                <div className="course--mini--name">
                                                 <a className="animsition-link" href={ item.course_link.url } target="_blank" rel="noopener">
                                                    <span className="font--b fontsize-sm c-black display-block">
                                                      { item.course_name.text }
                                                    </span>
                                                  </a>
                                                </div>
                                                <div className="course--mini--review">
                                                  { item.course_review.text &&
                                                    <div>
                                                      <span className="inv-icon icon-star-fill fontsize-l c-yellow display-inlineblock"></span>
                                                      <span className="font--sb fontsize-xs c-black display-inlineblock review-avg">{ item.course_review.text } ({ item.course_review_count.text })</span>
                                                    </div>
                                                  }
                                                </div>
                                                <div className="course--mini--badge">
                                                  { item.course_tags_list.text &&
                                                    <div className="badge badge--purple marginright-xs">
                                                      { item.course_tags_list.text }
                                                    </div>
                                                  }
                                                </div>
                                                <div className="display-block margintop-m">
                                                  <span className="fontsize-xs font--r c-black display-block">{ item.course_time_label }</span>
                                                  <span className="fontsize-sm font--sb c-black display-block">{ item.course_time_text.text }</span>
                                                </div>
                                                <div className="display-block flex-vcenter margintop-xs">
                                                  <div className="font--sb fontsize-s force-empty-paragraph"></div>
                                                </div>
                                                <div className="margintop-xs">
                                                  <a className="animsition-link" href={ item.course_link.url }>
                                                    <button className="button button--m button--full bg-red ">
                                                      <span className="font--sb fontsize-sm font--sb c-white">S'inscrire</span>
                                                    </button>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </Flickity>
                                        <div className="textalign-center margintop-xl">
                                          { slice.primary.bottom_button_url &&
                                            <a href={ slice.primary.bottom_button_url.url } target="_blank" rel="noopener">
                                              <div className="button button--m bg-white">
                                                <span className="font--sb fontsize-sm font--sb c-black">{ slice.primary.bottom_button_name.text }</span>
                                              </div>
                                            </a>
                                          }
                                        </div>
                                      </div>
                                    </div>

                          default:
                              return null
                      }
                  })()}
                </div>
            )) }
          </div>
        }

        <section>
          <div className="wrapper--xs">
            <div className="display-block position-relative width-100 marginbottom-xl">
              <div className="flex-vcenter">
                <div className="display-inlineblock marginright-m">
                  <span className="fontsize-sm font--r c-black">Partager</span>
                </div>
                <div className="display-inlineblock marginright-xs">
                  <a href={ fburl } target="_blank" rel="noopener noreferrer">
                    <span className="inv-icon icon-facebook fontsize-l c-black"></span>
                  </a>
                </div>
                <div className="display-inlineblock marginright-xs">
                  <a href={ twitterurl } target="_blank" rel="noopener noreferrer">
                    <span className="inv-icon icon-twitter fontsize-l c-black"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="display-block position-relative width-100">
              { data.theArticle.data.link_tags != null && data.theArticle.data.link_tags.map((tag, index) => (
                <div className="display-inlineblock" key={index}>
                  { tag.tag != null && tag.tag.document.map((doc) => (
                      <Link key={doc.id} to={`/tag/${ doc.uid }/`}>
                        <div className="article-tag">
                          <span >{ doc.data.name.text }</span>
                        </div>
                      </Link>
                  )) }
                </div>
              )) }
            </div>
            <div className="display-block position-relative width-100">
              <div className="article-author--l">
                <div className="article-author--picture">
                  { data.theArticle.data.author.document.map((author) => (
                    <img key={ author.id } src={ author.data.image.url } alt=""/>
                  )) }
                </div>
                { data.theArticle.data.author.document.map((author) => (
                    <span key={ author.id } className="article-author--name">{ author.data.name.text }</span>
                )) }
                <span className="article-author--date">{ data.theArticle.data.last_publication_date }</span>
              </div>
            </div>
          </div>
        </section>
        { !selection &&
          <section>
            <div className="wrapper--s">
              <div className="flex-vcenter width-100">
                <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">Derniers articles</span>
                <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
                </div>
                <Link to="/" className="link link-purple link--underline font--r fontsize-s marginleft-m whitespace-nowrap">See all</Link>
              </div>
              <ArticleGrid
                data={data.threeLatest}
              />
            </div>
          </section>
        }
      </div>
    );

  }

}


export default ArticleTemplate

export const query = graphql`
fragment singleArticleFields on PrismicArticle {
  slugs
  id
  uid
  data {
    title {
      text
    }
    preview {
      text
    }
    cover {
      url
    }
    category {
      document {
        id
        data {
          category_name {
            text
          }
        }
        uid
      }
    }
    publication_date(formatString:"MMM D, YYYY")
    author {
      document {
        id
        data {
          name {
            text
          }
          image {
            url
          }
        }
      }
    }
  }
}
query articleTemplate($id: String!) {
  theArticle: prismicArticle(id: {eq: $id}) {
    id
    last_publication_date(formatString:"MMM D, YYYY")
    data {
      body {
        ... on PrismicArticleBodyText {
          slice_type
          primary {
            slice_text {
              html
            }
          }
        }
        ... on PrismicArticleBodyMediumImage {
          slice_type
          primary {
            medium_width_image{
              url
              dimensions {
                width
                height
              }
            }
            caption
            alt
          }
        }
        ... on PrismicArticleBodyBigImage {
          slice_type
          primary {
            full_width_image {
              url
              dimensions {
                width
                height
              }
            }
            caption
            alt
          }
        }
        ... on PrismicArticleBodyQuote {
          slice_type
          primary {
            quote
          }
        }
        ... on PrismicArticleBodyImage {
          slice_type
          primary {
            small_with_image {
              url
              dimensions {
                width
                height
              }
            }
            caption
            alt
          }
        }
        ... on PrismicArticleBodyVideo {
          slice_type
          primary {
            embed {
              embed_url
            }
            caption
          }
        }
        ... on PrismicArticleBodyGallery {
          slice_type
          items {
            image {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
        ... on PrismicArticleBodyButton {
          slice_type
          primary {
            button_label {
              text
            }
            button_link {
              url
            }
          }
        }
        ... on PrismicArticleBodyMailForm {
          slice_type
          primary {
            id_training
            custom_text_message {
              text
            }
            input_label
            cta_label
          }
        }
        ... on PrismicArticleBodyAd {
          slice_type
          primary {
            ad_image {
              url
            }
            institution
            course_name
            course_overview
            course_link {
              url
            }
          }
        }
        ... on PrismicArticleBodyCourseSlide {
          slice_type
          primary {
            bottom_button_name {
              text
            }
            bottom_button_url {
              url
            }
          }
          items {
            course_name {
              text
            }
            course_department_name {
              text
            }
            course_department_logo {
              url
            }
            course_review {
              text
            }
            course_review_count {
              text
            }
            course_time_label
            course_time_text {
              text
            }
            course_link {
              url
            }
            course_tags_list {
              text
            }
          }
        }
      }
      link_tags {
        tag {
          document {
            id
            uid
            data {
              name {text}
            }
          }
        }
      }
      meta_title {
        text
      }
      meta_description {
        text
      }
      category {
        url
        raw {
          slug
        }
        document {
          id
          data {
            category_name {
              text
            }
          }
        }
      }
      cover {
        url
        dimensions {
          width
          height
        }
      }
      title {
        text
      }
      preview {
        text
      }
      author {
        url
        document {
          id
          data {
            name {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
  }
  threeLatest: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    ne: "Draft"
                  }
                }
              }
            }
          }
        }
      }
    }
    limit: 3
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...singleArticleFields
      }
    }
  }
}
`
